var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { resolve } from "url";
import * as Sentry from "@sentry/browser";
import LazyLoad from "vanilla-lazyload";
export var NON_FIELD_ERRORS = "__all__";
export var GENERIC_ERROR_MESSAGE = gettext("Oops, something went wrong. Please, try again later.");
export function staticfile(path) {
    // TODO (ts)
    return resolve(window.staticUrl, path);
}
export function flatten(list) {
    var reducer = function (acc, currentValue) {
        return acc.concat(Array.isArray(currentValue) ? flatten(currentValue) : currentValue);
    };
    return list.reduce(reducer, []);
}
export function formatPrice(value, decimalPlaces) {
    if (decimalPlaces === void 0) { decimalPlaces = 2; }
    var ret = typeof value === "number" ? value : parseFloat(value);
    return ret.toFixed(decimalPlaces).replace(".", ",");
}
export function actionIs(name) {
    return function (_a) {
        var action = _a.action;
        return name === action;
    };
}
/**
 *
 * @param window Window
 * @param document Document
 * @param eventStream Rx.Subject
 * @param interfaces Map where `key` is css selector and `value` is a function
 *          that accepts (window, document, selector, eventStream) arguments
 */
export function bindInterfaces(window, document, eventStream, interfaces) {
    Object.keys(interfaces).map(function (selector) {
        var fn = interfaces[selector];
        fn(window, document, selector, eventStream);
    });
}
export function loadStyles(stylesheets, cb) {
    // Create string ID of required CSS URLs
    var signature = btoa(JSON.stringify(stylesheets));
    // make urlsafe version of marker
    var marker = signature.replace("+", "-").replace("/", "_").replace(/=+$/, "");
    var elementId = "dyncss-".concat(marker);
    var s = document.getElementById(elementId);
    if (!s) {
        Promise.all(stylesheets.map(function (url) { return fetch(url); }))
            .then(function (arr) { return Promise.all(arr.map(function (url) { return url.text(); })); })
            .then(function (arr) {
            var style = document.createElement("style");
            style.id = elementId;
            style.textContent = arr.reduce(function (prev, fileContents) { return prev + fileContents; }, "");
            if (document.head) {
                document.head.appendChild(style);
            }
        })
            .then(function () {
            cb();
        });
    }
    else {
        cb();
    }
}
/**
 * Bind events for elements (including dynamically created elements) by delegating event to parent node
 * Usage: delegateEvent(document, 'click', '#my-dynamic-button', (el, event) => {})
 *
 * @param parent Node
 * @param eventName String
 * @param childSelector String
 * @param cb Function
 */
export function delegateEvent(parent, eventName, childSelector, cb) {
    parent.addEventListener(eventName, function (event) {
        var clickedElement = event.target;
        if (clickedElement instanceof Element) {
            var matchingChild = clickedElement.closest(childSelector);
            if (matchingChild) {
                cb(matchingChild, event);
            }
        }
    });
}
export function createError(message, fieldName, code) {
    var _a;
    if (fieldName === void 0) { fieldName = NON_FIELD_ERRORS; }
    if (code === void 0) { code = null; }
    return _a = {},
        _a[fieldName] = [
            {
                code: code,
                message: message,
            },
        ],
        _a;
}
export function convertHex(hex, opacity) {
    hex = hex.replace("#", "");
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
}
export function getNonFieldErrors(errors) {
    return errors[NON_FIELD_ERRORS];
}
export function toGetParams(obj, skipEmptyValues) {
    if (skipEmptyValues === void 0) { skipEmptyValues = true; }
    var q = [];
    Object.keys(obj)
        .map(function (k) { return [k, obj[k]]; })
        .filter(function (_a) {
        var _b = __read(_a, 2), _ = _b[0], v = _b[1];
        if (skipEmptyValues) {
            return v !== null && v !== "" && typeof v !== "undefined";
        }
        return true;
    })
        .map(function (_a) {
        var _b = __read(_a, 2), k = _b[0], v = _b[1];
        if (Array.isArray(v)) {
            Array.from(v)
                .filter(function (i) { return i !== null && i !== "" && typeof i !== "undefined"; })
                .map(function (i) {
                q.push(encodeURIComponent(k) + "=" + encodeURIComponent(i));
            });
        }
        else {
            q.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
    });
    var getParams = q.join("&");
    return getParams ? "?" + getParams : "";
}
export function decodeHTML(escapedHtml) {
    var escapeEl = document.createElement("textarea");
    escapeEl.innerHTML = escapedHtml;
    return escapeEl.textContent;
}
export function svgContent(name) {
    return {
        __html: '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="' + window.spriteUrl + name + '"></use>',
    };
}
export function rupluralize(value, variants) {
    var amount = Math.abs(value);
    if (amount % 10 === 1 && amount % 100 !== 11) {
        return variants[0];
    }
    if (amount % 10 >= 2 && amount % 10 <= 4 && (amount % 100 < 10 || amount % 100 >= 20)) {
        return variants[1];
    }
    return variants[2];
}
export function errorsToString(errors) {
    // Convert long `errors` object to short string representation for reporting
    var reducer = function (acc, fieldName) {
        acc[fieldName] = errors[fieldName].map(function (e) { return e.message; });
        return acc;
    };
    return JSON.stringify(Object.keys(errors).reduce(reducer, {}));
}
export function getOffset(element) {
    if (!element.getClientRects().length) {
        return {
            top: 0,
            left: 0,
        };
    }
    var rect = element.getBoundingClientRect();
    var win = element.ownerDocument.defaultView;
    return {
        top: win ? rect.top + win.scrollY : 0,
        left: win ? rect.left + win.scrollX : 0,
    };
}
export function setCookie(cookieName, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cookieName) {
    var name = cookieName + "=";
    var decodedCookie;
    try {
        decodedCookie = decodeURIComponent(document.cookie);
    }
    catch (e) {
        return "";
    }
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
export function sendGaEvent(category, action, label, value, nonInteraction) {
    if (label === void 0) { label = null; }
    if (value === void 0) { value = 1; }
    if (nonInteraction === void 0) { nonInteraction = false; }
    gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
        non_interaction: nonInteraction,
    });
    sp("trackStructEvent", category, action, label, null, value);
}
export function getGaClientId(gaClient) {
    var clientId = null;
    gaClient.getAll().forEach(function (tracker) {
        var id = tracker.get("clientId");
        if (id) {
            clientId = id;
        }
    });
    return clientId;
}
export function isGoogleAnalyticsBlocked() {
    return !(window.ga && window.ga.create);
}
export function eventLabel(productIdOrIds, userId) {
    if (userId === void 0) { userId = null; }
    var items = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        items[_i - 2] = arguments[_i];
    }
    var tail = items.join("|");
    var ids = Array.isArray(productIdOrIds) ? productIdOrIds.join(",") : productIdOrIds;
    var label = userId ? "P".concat(ids, "|U").concat(userId) : "P".concat(ids);
    return tail ? [label, tail].join("|") : label;
}
export function getContext(_window, document, namespace) {
    if (namespace === void 0) { namespace = "default"; }
    var context = {};
    var selector = "script[type=\"application/json+context\"]#".concat(namespace);
    var contexts = document.querySelectorAll(selector);
    for (var i = 0; i < contexts.length; i++) {
        var obj = JSON.parse(contexts[i].textContent);
        context = Object.assign(context, obj);
    }
    return context;
}
export function setupSentry(dsn, currentUserId) {
    if (currentUserId === void 0) { currentUserId = null; }
    Sentry.init({
        dsn: dsn,
    });
    if (typeof currentUserId === "number") {
        Sentry.setUser({
            id: currentUserId.toString(),
        });
    }
}
export function lazyLoadImages(selector) {
    if (selector === void 0) { selector = ".js-lazy-img"; }
    new LazyLoad({
        elements_selector: selector,
        data_bg: "src",
        callback_error: function (element) {
            if (element instanceof HTMLImageElement && element.dataset.placeholder) {
                element.src = element.dataset.placeholder;
            }
            element.classList ? element.classList.add("lazy-error") : (element.className += " " + "lazy-error");
        },
    });
}
export function reportXhrError(err) {
    Sentry.captureException(err);
}
// Filters array of `errors` by `code`
export function errorsByCode(errors) {
    var code = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        code[_i - 1] = arguments[_i];
    }
    return errors.filter(function (err) { return err.code && code.includes(err.code); });
}
export function nextWithEvent(_response, eventStream, event // TODO (ts)
) {
    // TODO restore this when fully migrated to `fetch()`
    // if (!resp.body) {
    //     nextWithXhrError(eventStream, `Couldn't read resp.body: [${resp.status}] ${resp.text}`)
    // }
    eventStream.next(event);
}
export function nextWithXhrError(eventStream, err) {
    eventStream.next({
        action: "xhrErrorCaught",
        error: err,
    });
}
/**
 * Takes `obj` properties and makes FormData from them
 * @param obj
 * @returns {FormData}
 */
export function formdata(obj) {
    var fd = new FormData();
    Array.from(Object.keys(obj)).forEach(function (k) {
        fd.append(k, obj[k]);
    });
    return fd;
}
export function httpGet(url, options) {
    var opts = options
        ? Object.assign({}, {
            method: "GET",
        }, options)
        : options;
    return fetch(url, opts);
}
export function httpPost(url, options) {
    var opts = options
        ? Object.assign({}, {
            method: "POST",
        }, options)
        : options;
    return fetch(url, opts);
}
export function timeSince(date) {
    var seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + " anos";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " meses";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " dias";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " horas";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutos";
    }
    return Math.floor(seconds) + " segundos";
}
export function animate(elem, style, from, to, time) {
    var start = new Date().getTime();
    var timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time);
        elem[style] = from + step * (to - from);
        if (step === 1) {
            clearInterval(timer);
        }
    }, 25);
    elem[style] = from;
}
var WebSocketWrapper = /** @class */ (function () {
    function WebSocketWrapper(url, options) {
        var _this = this;
        Object.defineProperty(this, "url", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "opts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ws", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "num", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "timer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "reconnect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.timer && _this.num++ < _this.opts.maxAttempts) {
                    _this.timer = setTimeout(function () {
                        _this.opts.onreconnect(e);
                        _this.open();
                    }, _this.opts.timeout);
                }
                else {
                    _this.opts.onmaximum(e);
                }
            }
        });
        Object.defineProperty(this, "close", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (code, reason) {
                _this.timer = clearTimeout(_this.timer);
                _this.ws.close(code || 1e3, reason);
            }
        });
        var o = options || {};
        this.url = url;
        var noop = function () { };
        this.opts = {
            protocols: o.protocols || [],
            maxAttempts: o.maxAttempts || Infinity,
            timeout: o.timeout || 100,
            onopen: o.onopen || noop,
            onmessage: o.onmessage || noop,
            onclose: o.onclose || noop,
            onerror: o.onerror || noop,
            onreconnect: o.onreconnect || noop,
            onmaximum: o.onmaximum || noop,
        };
        this.ws = null;
        this.num = 0;
        this.timer = 1;
        this.open();
    }
    Object.defineProperty(WebSocketWrapper.prototype, "open", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.ws = new WebSocket(this.url, this.opts.protocols);
            this.ws.onmessage = this.opts.onmessage;
            this.ws.onopen = function (e) {
                _this.opts.onopen(e);
                _this.num = 0;
            };
            this.ws.onclose = function (e) {
                e.code === 1e3 || e.code === 1001 || e.code === 1005 || _this.reconnect(e);
                _this.opts.onclose(e);
            };
            this.ws.onerror = function (e) {
                e && e.code === "ECONNREFUSED" ? _this.reconnect(e) : _this.opts.onerror(e);
            };
        }
    });
    return WebSocketWrapper;
}());
export { WebSocketWrapper };
/**
 * Defines AlpineJS component
 */
export var defineComponent = function (fn) { return fn; };
