import { sendGaEvent, lazyLoadImages } from "../utils";
import getSetReff from "./getsetreff";
import clip from "text-clipper";
import flatpickr from "flatpickr";
document.addEventListener("DOMContentLoaded", function () {
    // check is serving from trusted domain
    if (window.safeHost && document.location.host !== window.safeHost) {
        window.S.csp_report(document.location.href, function () {
            document.location.host = window.safeHost;
        });
    }
    // Fixed header
    var header = document.querySelector(".js-fixed_header");
    if (header) {
        window.addEventListener("scroll", function () {
            if (window.pageYOffset > 130) {
                // TODO Fix deprecated property
                header.classList.add("b-header-fixed_type_shown");
            }
            else {
                header.classList.remove("b-header-fixed_type_shown");
            }
        });
    }
    // sort select
    Array.from(document.querySelectorAll(".js-sort-select")).forEach(function (el) {
        el.addEventListener("change", function (ev) {
            if (!(ev.currentTarget instanceof HTMLSelectElement)) {
                return;
            }
            var value = ev.currentTarget.value;
            var qs = el.dataset.qs;
            if (qs) {
                qs += "&sort=" + value;
            }
            else {
                qs = "?sort=" + value;
            }
            window.location.search = qs;
        });
    });
    // bind GA events
    Array.from(document.querySelectorAll(".js-ga-onclick")).forEach(function (el) {
        el.addEventListener("click", function () {
            var e = el.dataset;
            var isNonInteraction = Boolean(el.dataset.eventNonInteraction);
            sendGaEvent(e.eventCategory, e.eventAction, e.eventLabel, 1, isNonInteraction);
        });
    });
    Array.from(document.querySelectorAll(".js-ga-onsubmit")).forEach(function (el) {
        el.addEventListener("submit", function () {
            var e = el.dataset;
            sendGaEvent(e.eventCategory, e.eventAction, e.eventLabel);
        });
    });
    // js-link
    Array.from(document.querySelectorAll(".js-link")).forEach(function (el) {
        el.addEventListener("click", function (ev) {
            ev.preventDefault();
            ev.stopPropagation();
            var _a = el.dataset, href = _a.href, target = _a.target;
            if (href) {
                if (target) {
                    window.open(href, target);
                }
                else {
                    window.location = href;
                }
            }
        });
    });
    // lazyload images
    lazyLoadImages();
    /*setCookie
     * Toggle dropdown menus.
     *
     * Menus must open/close on mouse click.
     * Click on outer area must close all opened menus.
     * Click on certain menu must close other opened menus.
     * TODO Optimize toggle logic below
     */
    // dropdown profile menu
    Array.from(document.querySelectorAll(".js-profile-menu")).forEach(function (el) {
        el.addEventListener("click", function () {
            el.classList.toggle("b-header-links__profile_state_opened");
            Array.from(document.querySelectorAll(".js-catalog-menu")).forEach(function (_el) {
                return _el.classList.remove("b-nav__item_state_opened");
            });
            Array.from(document.querySelectorAll(".js-catalog-mobile-menu")).forEach(function (_el) {
                return _el.classList.remove("b-nav__mobile-menu_state_opened");
            });
        });
    });
    // dropdown catalog menu
    Array.from(document.querySelectorAll(".js-catalog-menu")).forEach(function (el) {
        el.addEventListener("click", function () {
            Array.from(document.querySelectorAll(".js-catalog-menu")).forEach(function (_el) {
                return _el.classList.remove("b-nav__item_state_opened");
            });
            Array.from(document.querySelectorAll(".js-profile-menu")).forEach(function (_el) {
                return _el.classList.remove("b-header-links__profile_state_opened");
            });
            el.classList.toggle("b-nav__item_state_opened");
        });
    });
    // dropdown mobile catalog menu
    Array.from(document.querySelectorAll(".js-catalog-mobile-menu")).forEach(function (el) {
        el.addEventListener("click", function () {
            el.classList.toggle("b-nav__mobile-menu_state_opened");
            Array.from(document.querySelectorAll(".js-profile-menu")).forEach(function (_el) {
                return _el.classList.remove("b-header-links__profile_state_opened");
            });
        });
    });
    // Click on outer area must close opened menus
    document.addEventListener("click", function (ev) {
        Array.from(document.querySelectorAll(".js-dropdown")).forEach(function (el) {
            if (el !== ev.target && ev.target instanceof Node && !el.contains(ev.target)) {
                Array.from(document.querySelectorAll(".js-profile-menu")).forEach(function (_el) {
                    return el.classList.remove("b-header-links__profile_state_opened");
                });
                Array.from(document.querySelectorAll(".js-catalog-menu")).forEach(function (_el) {
                    return el.classList.remove("b-nav__item_state_opened");
                });
                Array.from(document.querySelectorAll(".js-catalog-mobile-menu")).forEach(function (_el) {
                    return el.classList.remove("b-nav__mobile-menu_state_opened");
                });
            }
        });
    });
    // Toggles
    Array.from(document.querySelectorAll(".js-toggle")).forEach(function (el) {
        el.addEventListener("click", function () {
            var target = document.querySelector("#".concat(el.dataset.targetId));
            if (target) {
                target.classList.toggle(el.dataset.toggleClassname);
                if (el.dataset.toggleSelfHide) {
                    el.style.display = "none";
                }
            }
        });
    });
    /*
      js-truncated
   */
    Array.from(document.querySelectorAll(".js-truncated")).forEach(function (el) {
        // get original text
        var original = el.querySelector(".".concat(el.dataset.textClass));
        if (!original) {
            return;
        }
        // if it's too short - just skip truncating
        var maxLength = parseInt(el.dataset.length);
        if (original.textContent.length < maxLength) {
            return;
        }
        // make truncated version of original text
        var clippedHtml = clip(original.innerHTML, maxLength, {
            html: true,
        });
        var truncated = document.createElement("div");
        truncated.classList.add(el.dataset.textClass);
        truncated.innerHTML = clippedHtml;
        // ellipsis
        var ellipsis = document.createElement("span");
        ellipsis.textContent = "...";
        // more link
        var moreLink = document.createElement("a");
        moreLink.classList.add("b-link_theme_green");
        moreLink.href = "#more";
        moreLink.innerText = gettext("читать дальше");
        moreLink.addEventListener("click", function (ev) {
            ev.preventDefault();
            original.style.display = "";
            truncated.style.display = "none";
            return false;
        });
        // append ellipsis and "more" link  to truncated text
        var tl = truncated.querySelector("p:last-child");
        if (tl) {
            tl.appendChild(ellipsis);
            tl.appendChild(moreLink);
        }
        // less link
        var lessLink = document.createElement("a");
        lessLink.classList.add("b-link_theme_green");
        lessLink.href = "#less";
        lessLink.innerText = gettext("свернуть");
        lessLink.addEventListener("click", function (ev) {
            ev.preventDefault();
            original.style.display = "none";
            truncated.style.display = "";
            return false;
        });
        // append "show less" link to original text
        var ol = original.querySelector("p:last-child");
        if (ol) {
            ol.appendChild(lessLink);
        }
        // hide original text
        original.style.display = "none";
        // insert and show truncated text
        el.appendChild(truncated);
    });
    // js-roll
    Array.from(document.querySelectorAll(".js-roll")).forEach(function (el) {
        el.addEventListener("click", function (ev) {
            ev.preventDefault();
            var target = document.querySelector("#".concat(el.dataset.targetId));
            if (target) {
                target.classList.toggle("active");
                if (target.classList.contains("active")) {
                    el.innerHTML = gettext("свернуть");
                }
                else {
                    el.innerHTML = gettext("читать дальше");
                }
            }
        });
    });
    // js-collapse
    Array.from(document.querySelectorAll(".js-collapse")).forEach(function (el) {
        el.addEventListener("click", function (ev) {
            ev.preventDefault();
            if (!(ev.currentTarget instanceof HTMLElement)) {
                return;
            }
            var dataset = ev.currentTarget.dataset;
            var target = document.querySelector("#".concat(dataset.targetId));
            var source = document.querySelector("#".concat(dataset.sourceId));
            if (target) {
                target.classList.toggle(dataset.toggleClassname);
            }
            if (source) {
                source.style.display = "none";
            }
        });
    });
    // Tabs
    Array.from(document.querySelectorAll(".js-tabs_button")).forEach(function (el) {
        el.addEventListener("click", function (_ev) {
            var tabsList = el.nextElementSibling;
            if (tabsList && tabsList instanceof HTMLElement) {
                tabsList.classList.toggle(tabsList.dataset.classactive);
            }
        });
    });
    // Logout link
    Array.from(document.querySelectorAll(".js-logout-link")).forEach(function (el) {
        el.addEventListener("click", function (_ev) {
            var form = document.querySelector("#".concat(el.dataset.formId));
            if (form && form instanceof HTMLFormElement) {
                form.submit();
            }
            return false;
        });
    });
    // Login link
    // tricky way to disable double clicks causing login errors
    Array.from(document.querySelectorAll(".js-doubleclick-prevent")).forEach(function (el) {
        el.addEventListener("click", function (ev) {
            if (el.classList.contains("js-doubleclick--disabled")) {
                ev.preventDefault();
                return;
            }
            el.textContent = "".concat(el.textContent, "...");
            el.classList.add("js-doubleclick--disabled");
        });
    });
    // profile settings photo
    Array.from(document.querySelectorAll(".js-photo-input")).forEach(function (el) {
        if (!(el instanceof HTMLInputElement)) {
            return;
        }
        el.addEventListener("change", function (_ev) {
            var name = document.querySelector(".js-photo-input-name");
            var help = document.querySelector(".js-photo-help-text");
            if (name && el.files.length > 0) {
                name.textContent = el.files[0].name;
            }
            if (help) {
                help.classList.remove("b-hidden");
            }
        });
    });
    // Run getSetReff script to record traffic history for visitor
    getSetReff();
    // cookie confirmation bar
    var cookieBtn = document.getElementById("confirm-cookies");
    if (cookieBtn) {
        cookieBtn.addEventListener("click", function () {
            var expiration_date = 365;
            var now = new Date();
            now.setDate(now.getDate() + expiration_date);
            document.cookie = "cookies-confirmation=1; expires=" + now.toUTCString() + "; path=/";
            this.parentElement.parentElement.classList.add("b-hidden");
        });
    }
    // sidebar
    if (localStorage.getItem("sidebar-expanded") == "true") {
        document.querySelector("body").classList.add("sidebar-expanded");
    }
    else {
        document.querySelector("body").classList.remove("sidebar-expanded");
    }
    // init flatpickr
    flatpickr(".datepicker", {
        mode: "range",
        static: true,
        monthSelectorType: "static",
        dateFormat: "M j, Y",
        defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
        prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
        nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
        onReady: function (_selectedDates, dateStr, instance) {
            instance.input.value = dateStr.replace("to", "-");
            var customClass = instance.element.getAttribute("data-class");
            instance.calendarContainer.classList.add(customClass);
        },
        onChange: function (_selectedDates, dateStr, instance) {
            instance.input.value = dateStr.replace("to", "-");
        },
    });
});
