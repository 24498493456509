import { getCookie, setCookie } from "../utils";
export default function getSetReff() {
    var _reff; //settings
    var maxVisits = 7; //max referrer values
    var utmCampaign = "utm_campaign";
    var utmMedium = "utm_medium";
    var utmSource = "utm_source";
    var utmContent = "utm_content";
    var utmTerm = "utm_term";
    var timings = [];
    var lastRecord = "";
    var prefix = "";
    var referrer = "";
    var isNewSession = false;
    var landingPage;
    var sessionCookieLifetime = (1 / 24 / 60) * 30; //half an hour cookie
    window.dataLayer = window.dataLayer || [];
    _reff = window.dataLayer.filter(function (value) {
        if (value.setDomain)
            return value;
    });
    if (_reff.length === 0)
        _reff[0] = {
            setDomain: document.location.hostname,
        };
    //get cookies
    var sessionCookie = typeof getCookie("__sreff") != "undefined" ? getCookie("__sreff") : ""; // session cookie
    var persistentCookie = typeof getCookie("__reff") != "undefined"
        ? getCookie("__reff")
            .split("|")
            .slice(1 - maxVisits)
            .join("|")
        : ""; // long term cookie
    function getParam(p) {
        if (document.location.search.indexOf(p) !== -1) {
            return ("" + document.location.search.split(p + "=")[1]).split("&")[0];
        }
        else {
            return "not-set";
        }
    }
    //referrer or params?
    if (document.location.search.indexOf(utmCampaign) !== -1 ||
        document.location.search.indexOf(utmMedium) !== -1 ||
        document.location.search.indexOf(utmSource) !== -1) {
        referrer = "//campaign::";
        referrer += getParam(utmCampaign) != "not-set" ? "c:[" + getParam(utmCampaign) + "]" : "";
        referrer += getParam(utmMedium) != "not-set" ? "m:[" + getParam(utmMedium) + "]" : "";
        referrer += getParam(utmSource) != "not-set" ? "s:[" + getParam(utmSource) + "]" : "";
        referrer += getParam(utmContent) != "not-set" ? "o:[" + getParam(utmContent) + "]" : "";
        referrer += getParam(utmTerm) != "not-set" ? "t:[" + getParam(utmTerm) + "]" : "";
    }
    else {
        referrer = document.referrer;
    }
    referrer =
        document.location.search.indexOf("gclid") != -1
            ? "//campaign::cr:[" + getParam("gclid") + "]m:[paid]s:[adwords]"
            : referrer;
    referrer =
        typeof referrer == "undefined" || referrer == "" || referrer.indexOf(_reff[0].setDomain) != -1
            ? "(direct)"
            : referrer.split("/")[2];
    landingPage = "[[" + document.location.hostname + document.location.pathname + "]]";
    if (sessionCookie) {
        timings = sessionCookie.split(".").map(function (item) { return parseInt(item); });
        timings[1] = new Date().getTime();
        timings[2]++;
        sessionCookie = timings.join(".");
        setCookie("__sreff", sessionCookie, sessionCookieLifetime);
    }
    else {
        timings[0] = timings[1] = new Date().getTime(); //start time = current time
        timings[2] = 1; //first pageview
        sessionCookie = timings.join(".");
        isNewSession = true;
        setCookie("__sreff", sessionCookie, sessionCookieLifetime);
    }
    //if new session
    if (isNewSession) {
        prefix = persistentCookie != "" ? persistentCookie + "|" : "";
        setCookie("__reff", prefix + landingPage + referrer + "&" + sessionCookie, 730);
    }
    else {
        var records = persistentCookie.split("|");
        lastRecord = records[records.length - 1];
        lastRecord = lastRecord.split("&")[0] != "" ? lastRecord.split("&")[0] : landingPage + referrer;
        prefix = records.length == 1 ? "" : records.slice(0, -1).join("|") + "|";
        if (referrer == "(direct)" || lastRecord.split("&")[0].indexOf(referrer) != -1) {
            setCookie("__reff", prefix + lastRecord + "&" + sessionCookie, 730);
        }
        else {
            setCookie("__reff", persistentCookie + "|" + landingPage + referrer + "&" + sessionCookie, 730);
        }
    }
    return getCookie("__reff");
}
